import { Button } from "antd";
import styled from "styled-components";

export const Wrap = styled.div`
  min-height: 80vh;
  background-color: white;
`;

export const LoginPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background: #d9edff;
  .form-control::placeholder {
    font-size: 12px;
    opacity: 0.7;
  }
`;

export const LoginBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
`;

export const LoginForm = styled.form`
  flex: 1 0 100%;
  width: 480px;
  padding: 60px;
  @media only screen and (max-width: 786px) {
    max-width: 480px;
    width: 100%;
    padding: 20px;
  }
`;

export const FormTitle = styled.p`
  text-align: center;
  margin-bottom: 30px;
  color: #333333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
`;
export const FormDesc = styled.p`
  color: #333333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 15px;
  text-align: center;
`;
export const FormButton = styled.div`
`;
export const CustomButton = styled(Button)`
  height: 42px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 20px;
`;
