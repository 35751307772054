/* eslint-disable react-hooks/exhaustive-deps */
import React ,{ useEffect } from "react";

import {
  CustomButton,
  FormButton,
  FormDesc,
  FormTitle,
  LoginBox,
  LoginForm,
  LoginPage,
  Wrap,
} from "./styled";

import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput, Notification } from "Components";
import { useTranslation } from "react-i18next";
import { loginAdmin } from "Apis/admin";
import { ERROR_API_MESSAGE, RESPONSE_STATUS } from "Constants";
import { STORAGE, getLocalStorage, removeLocalStorage, setLocalStorage } from "Utils/storage";
import { useHistory } from "react-router-dom";
import FormInputPassword from "Components/Form/FormInputPassword";
import { useSelector } from "react-redux";
import actions from "Store/Actions";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { VALUE_ROLE } from "Utils/data-default";

const {
  resetState
  } = actions;
const Login = () => {
	const dispatch = useDispatch();
  const [t] = useTranslation("admin");
  const history = useHistory();

  const {
		info_maintenance_student
  } = useSelector((state) => state.student);
  
  const token = (getLocalStorage(STORAGE.ADMIN_TOKEN))
  const role = (getLocalStorage(STORAGE.ROLE_ADMIN))
  useEffect(() => {
    if (!_.isEmpty(info_maintenance_student)  && token && VALUE_ROLE[role] === 'SUPER_ADMIN') {
      history.push({
        pathname: `/admin/import_flow`,
      })
    }
    else if (!_.isEmpty(info_maintenance_student) && Number(info_maintenance_student?.status_maintain) === 1 && token) {
      removeLocalStorage(STORAGE.ADMIN_TOKEN)
      dispatch(resetState((action, res) => { }));
      history.push({
        pathname: `/admin/login`,
      })
    }
    
  },[info_maintenance_student])
  const validationSchema = yup
    .object({
      userID: yup.string().required(t('message.field_is_required_userID')),
      password: yup.string().required(t('message.field_is_required_password')),
    })
    .required();

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    // setValue,
    // reset,
    // clearErrors,
    // formState: { errors },
  } = form;

  const onSubmit = async (data) => {
    try {
      const res = await loginAdmin(data);

      if (res.data.status === RESPONSE_STATUS.SUCCESS) {
        setLocalStorage(STORAGE.ADMIN_TOKEN, res.data.data.token)
        setLocalStorage(STORAGE.ROLE_ADMIN, res.data.data.role)
        history.push({
          pathname: `/admin/import_flow`,
        })
      }
    } catch (error) {
      Notification.error(ERROR_API_MESSAGE[error.message_code] || ERROR_API_MESSAGE[error.code] || error.message);
    }
  };
  return (
    <Wrap>
      <LoginPage>
        <LoginBox>
          <FormProvider {...form}>
            <LoginForm
              name="login-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormTitle>{t('welcome_back')}</FormTitle>
              <FormDesc>{t('login_to_the_dashboard')}</FormDesc>
              <FormInput
                label={t('userID')}
                name="userID"
                type="text"
                placeholder={t('please_enter_username')}
              />
              <FormInputPassword
                label={t('password')}
                name="password"
                type="password"
                placeholder={t('please_enter_password')}
              />
              <FormButton>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  {t('login')}
                </CustomButton>
              </FormButton>
            </LoginForm>
          </FormProvider>
        </LoginBox>
      </LoginPage>
    </Wrap>
  );
};

export default Login;
